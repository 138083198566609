import React from 'react'
import images from '../../../ressources/images'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './AppSlider.scss'
import Icon from '../../../components/Icon/Icon';
import { withTranslation } from 'react-i18next';

function AppSlider(props) {
  const{t} = props
  return (
    <section className="app_slider container">
      <div className="left_part">
        <Carousel
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          interval={4000}
          infiniteLoop
          autoPlay
        >
          <div className="slide">
            <img src={images.slide1} alt=""/>
          </div>
          <div className="slide">
            <img src={images.slide2} alt=""/>
          </div>
          <div className="slide">
            <img src={images.slide3} alt=""/>
          </div>
        </Carousel>
      </div>
      <div className="right_part">
        <div className="wrapper">
          <h3>{t('explanations:title')}</h3>
          <p className="baseline">
            {t('explanations:baseline')}
          </p>
          <div className="features_wrapper">
            <div className="features_container">
              <div className="feature">
                <Icon
                  color='pink'
                  icon='lightning'
                />
                <div className="feature_text">
                  <h4>{t('explanations:scan')}</h4>
                  <p>
                    {t('explanations:scan_text')}
                  </p>
                </div>
              </div>
              <div className="feature">
                <Icon
                  color='pink'
                  icon='lightning'
                />
                <div className="feature_text">
                  <h4>{t('explanations:intervention')}</h4>
                  <p>
                    {t('explanations:intervention_text')}  
                  </p>
                </div>
              </div>
              <div className="feature">
                <Icon
                  color='pink'
                  icon='lightning'
                />
                <div className="feature_text">
                  <h4>{t('explanations:finish')}</h4>
                  <p>
                    {t('explanations:finish_text')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(AppSlider)
