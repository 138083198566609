import React from 'react'
import PropTypes from 'prop-types'
import images from '../../ressources/images'
import './Icon.scss'
function Icon(props) {

  const icons = () => {
    const srcImage = images[icon] || images.lightning
    return <img src={srcImage} className={'icon icon_'+icon} alt=''/>
  }

  const backgroundColor = () => {
    switch (color) {
      case 'pink':
        return("linear-gradient(to left top, #ee1663, #e62471, #dc307e, #d13b89, #c54592)")
    
      case 'red':
        return("linear-gradient(to left top, #ff4260, #ff4257, #ff444d, #ff4643, #ff4938)")
    
      case 'yellow':
        return("linear-gradient(to left top, #f37547, #f38046, #f28b45, #f19646, #efa049)")
    
      case 'purple':
        return("linear-gradient(to left top, #fe5663, #ff5470, #fe537d, #fc5489, #f95696)")
    
      default:
        return("linear-gradient(to left top, #ee1663, #e62471, #dc307e, #d13b89, #c54592)")
    }
  }

  const {
    className,
    color,
    icon,
    ...rest
  } = props

  const style = {
    background: backgroundColor()
  }

  return (
    <div {...rest} className={'icon_container ' + className + color}>
      <div className="icon_wrapper" style={style}>
        {icons()}
      </div>
      <i className={"circle "}></i>
      <i className={"cross "}></i>
      <i className={"star "}></i>
    </div>
  )
}

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['pink', 'red', 'yellow', 'purple']),
  icon: PropTypes.oneOf(['lightning', 'crown', 'bubble', 'brifecase']),
}

Icon.defaultProps = {
  className: '',
  color: 'pink',
  icon: 'lightning'
}

export default Icon

