import i18next from 'i18next';
import common_fr from './fr/common.json'
import top_fr from './fr/topPage.json'
import features_fr from './fr/features.json'
import explanations_fr from './fr/explanations.json'
import dashboard_fr from './fr/dashboard.json'
import contact_fr from './fr/contact.json'
import pricing_fr from './fr/pricing.json'
import common_en from './en/common.json'
import top_en from './en/topPage.json'
import features_en from './en/features.json'
import explanations_en from './en/explanations.json'
import dashboard_en from './en/dashboard.json'
import contact_en from './en/contact.json'
import pricing_en from './en/pricing.json'

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources: {
        fr: {
            common: common_fr,
            top: top_fr,
            features: features_fr,
            explanations: explanations_fr,
            dashboard: dashboard_fr,
            contact: contact_fr,
            pricing: pricing_fr
        },
        en: {
            common: common_en,
            top: top_en,
            features: features_en,
            explanations: explanations_en,
            dashboard: dashboard_en,
            contact: contact_en,
            pricing: pricing_en
        }
    },
});

// The method we'll use instead of a regular string
export default function Strings(name, params = {}) {
    return i18next.t(name, params);
};