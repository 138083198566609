import React from 'react'
import Fade from 'react-reveal/Fade'
import images from '../../../ressources/images'
import './TopPage.scss'
import { withTranslation } from 'react-i18next'

function TopPage(props) {
  const {t} = props
  return (
    <div id="home" className="top_page">
      <div className="top_wrapper container">
        <div className="left_part">
            <Fade bottom>
              <h1>                  
                {t('top:title')}
              </h1>
            </Fade>
          <Fade bottom>
            <h2>
              {t('top:subtitle')}
            </h2>
          </Fade>
        </div>
        <div className="right_part">
          <Fade bottom>
            <img src={images.phone} alt=""/>
          </Fade>
        </div>
      </div>
      <div className="companies">
        <div className="wrapper">
          <span>{t('top:trust')}</span>
          <ul>
            <li>Vilogia</li>
            <li>Keolis</li>
            <li>Cercle de la forme</li>
            <li>Company</li>
          </ul>
        </div>
      </div>
      <img src={images.white_corner} alt="" className="corner"/>
    </div>
  )
}

export default withTranslation()(TopPage)
