import React from 'react';
import '../ressources/styles/styles.scss';
import Nav from '../components/Nav/nav'
import TopPage from './Sections/TopPage/TopPage';
import Features from './Sections/Features/Features';
import AppSlider from './Sections/AppSlider/AppSlider';
import Dashboard from './Sections/Dashboard/Dashboard';
import Pricing from './Sections/Pricing/Pricing';
import Showcase from './Sections/Showcase/Showcase';
import Footer from './Sections/Footer/Footer';
// import Contact from './Sections/Contact/Contact';

function App() {
  return (
    <div className="poc">
      <Nav/>
      <TopPage/>
      <Features/>
      <AppSlider/>
      <Dashboard/>
      <Pricing/>
      <Showcase/>
      <Footer/>
      {/* <Contact/> */}
    </div>
  );
}

export default App;
