import React, { useState, useEffect } from 'react';
import './TarifTable.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { withTranslation } from 'react-i18next'

function TarifTable(props) {

    const [unitPrice, setUnitPrice] = useState(8)
    const {nbUser} = props

    const switchUnitPrice = () => {
        switch (nbUser) {
            case 25:
                setUnitPrice(8)
                break
            case 50:
                setUnitPrice(6)
                break
            case 100:
            case 200:
            case 500:    
                setUnitPrice(4)
                break
            case '501':
                break
            default:
                break
        }
    }

    useEffect(() => {
        switchUnitPrice()
    })

    const settings = {
        arrows: false,
        className: "mobile-only center",
        centerMode: true,
        centerPadding: 40,
        infinite: false,
        slidesToShow: 3,
        dots: true,      
        speed: 500,
        autoplay: false,
        responsive: [
            {
            breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    const {t} = props

    return (

        <div className="tarif-table__container">
            <div className="tarif-table">
                <Slider {...settings}>
                    <div className="tarif-column tarif-standard">
                        <div className="tarif-entete">
                            {t('pricing:monthly')}
                        </div>
                        <div className="tarif-content">
                            {nbUser === 501 ?
                                <>
                                    <div className="tarif-contact-text">
                                        {t('pricing:ask for an estimate')}
                                    </div>
                                    <button className="tarif-button">
                                        {t('pricing:contact us')}
                                    </button>
                                </>
                                :
                                <>
                                    <div className="tarif-user">
                                        {unitPrice}€/{t('pricing:user')}
                                    </div>
                                    <div className="tarif-price">
                                        {nbUser * unitPrice}€
                                    </div>
                                    <button className="tarif-button">
                                        {t('pricing:choose')}
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                    <div className="tarif-column tarif-annuel">
                        <div className="tarif-entete">
                            {t('pricing:annual')}
                            <span className="tarif-discount">
                                -5%
                            </span>
                        </div>
                        <div className="tarif-content">
                            {nbUser === 501 ?
                                <>
                                    <div className="tarif-contact-text">
                                        {t('pricing:ask for an estimate')}
                                    </div>
                                    <button className="tarif-button">
                                        {t('pricing:contact us')}
                                    </button>
                                </>
                                :
                                <>
                                    <div className="tarif-user">
                                        {unitPrice}€/{t('pricing:user')}
                                    </div>
                                    <div className="tarif-price">
                                        {nbUser * unitPrice * 12 * 0.95}€
                                    </div>
                                    <button className="tarif-button">
                                        {t('pricing:choose')} 
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </Slider>

                <div className="tarif-column desktop-only tarif-standard">
                    <div className="tarif-entete">
                        {t('pricing:standard')}
                    </div>
                    <div className="tarif-content">
                        {nbUser === 501 ?
                            <>
                                <div className="tarif-contact-text">
                                    {t('pricing:ask for an estimate')}
                                </div>
                                <button className="tarif-button">
                                    {t('pricing:contact us')}
                                </button>
                            </>
                            :
                            <>
                                <div className="tarif-user">
                                    {unitPrice}€/{t('pricing:user')}
                                </div>
                                <div className="tarif-price">
                                    {nbUser * unitPrice}€
                                </div>
                                <button className="tarif-button">
                                    {t('pricing:choose')}
                                </button>
                            </>
                        }
                    </div>
                </div>
                <div className="tarif-column desktop-only tarif-annuel">
                    <div className="tarif-entete">
                        {t('pricing:annual')}
                        <span className="tarif-discount">
                            -5%
                        </span>
                    </div>
                    <div className="tarif-content">
                        {nbUser === 501 ?
                            <>
                                <div className="tarif-contact-text">
                                    {t('pricing:ask for an estimate')}
                                </div>
                                <button className="tarif-button">
                                    {t('pricing:contact us')}
                                </button>
                            </>
                            :
                            <>
                                <div className="tarif-user">
                                    {unitPrice}€/{t('pricing:user')}
                                </div>
                                <div className="tarif-price">
                                    {nbUser * unitPrice * 12 * 0.95}€
                                </div>
                                <button className="tarif-button">
                                    {t('pricing:choose')} 
                                </button>
                            </>
                        }
                    </div>
                </div>

            </div>
        </div>

    )
}


export default withTranslation()(TarifTable)