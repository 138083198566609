const images = {
    // SVG
    logo: require('./images/logo.svg'),
    white_corner: require('./images/white_corner.svg'),
    lightning: require('./images/lightning.svg'),
    cross: require('./images/cross.svg'),
    circle: require('./images/circle.svg'),
    star: require('./images/star.svg'),
    bubble: require('./images/bubble.svg'),
    crown: require('./images/crown.svg'),
    brifecase: require('./images/brifecase.svg'),
    
    // PNG
    phone: require('./images/phone.png'),
    slide1: require('./images/slider/slide1.png'),
    slide2: require('./images/slider/slide2.png'),
    slide3: require('./images/slider/slide3.png'),
    dashboard: require('./images/dashboard.png'),
    smartphone: require('./images/smartphone.png'),
    showcase1: require('./images/showcase/showcase1.png'),
    showcase3: require('./images/showcase/showcase3.png'),
    showcase4: require('./images/showcase/showcase4.png'),
    showcase5: require('./images/showcase/showcase5.png'),
    showcase6: require('./images/showcase/showcase6.png'),
    showcase7: require('./images/showcase/showcase7.png'),
    showcase8: require('./images/showcase/showcase8.png'),

    // JPG
    showcase2: require('./images/showcase/showcase2.jpg'),
}

export default images;
