import React from 'react'
import Icon from '../../../components/Icon/Icon'
import Fade from 'react-reveal/Fade'
import './Features.scss'
import { withTranslation } from 'react-i18next'

function Features(props) {
  const {t} = props
  return (
    <section id="features" className="key_features container">
      <Fade bottom>
        <h5 className="baseline">{t('common:features')}</h5>
      </Fade>
      <Fade bottom>
        <h3 className="title">{t('features:why')}</h3>
      </Fade>
      <div className="keys">
        <Fade bottom delay={100}>
          <div className="key with_icon">
            <Icon
              color="pink"
              icon="lightning"
            />
            <h4 className="heading">{t('features:intuitive')}</h4>
            <p className="sentence">
              {t('features:intuitive_text')}
            </p>
          </div>
        </Fade>
        <Fade bottom delay={200}>
          <div className="key with_icon">
            <Icon
              color="red"
              icon="brifecase"
            />
            <h4 className="heading">{t('features:customizable')}</h4>
            <p className="sentence">
              {t('features:customizable_text')}
            </p>
          </div>
        </Fade>
        <Fade bottom delay={300}>
          <div className="key with_icon">
            <Icon
              color="purple"
              icon="crown"
            />
            <h4 className="heading">{t('features:support')}</h4>
            <p className="sentence">
              {t('features:support_text')}
            </p>
          </div>
        </Fade>
        <Fade bottom delay={400}>
          <div className="key with_icon">
            <Icon
              color="yellow"
              icon="bubble"
            />
            <h4 className="heading">{t('features:expertise')}</h4>
            <p className="sentence">
              {t('features:expertise_text')}
            </p>
          </div>
        </Fade>
      </div>
    </section>
  )
}

export default withTranslation()(Features)
