import React, { useState, useEffect } from 'react';
import './NumberSelector.scss';
import TarifTable from '../TarifTable/TarifTable';
import { withTranslation } from 'react-i18next'
import Select from 'react-select';

function NumberSelector(props) {

  const {t} = props

  // Nombre utilisateurs dans le select
  const options = [
    { value: 25, label: '25 ' + t('pricing:users') },
    { value: 50, label: '50 ' + t('pricing:users') },
    { value: 100, label: '100 ' + t('pricing:users') },
    { value: 200, label: '200 ' + t('pricing:users') },
    { value: 500, label: '500 ' + t('pricing:users') },
    { value: 501, label: '+ 500 ' + t('pricing:users') },
  ];

  const [nbUser, setNbUser] = useState(25)
  const [selectedOption, setSelectedOption] = useState(options[0])
  const numbers = [25, 50, 100, 200, 500, 501];


  // Au changement dans le select
  const handleChange = (selectedOption) => {
    // Mise à jour de l'option sélectionnée
    setSelectedOption(selectedOption)  
    // On récupère l'élement de la liste grâce à la classe number-selector-x ou x vaut le nombre d'utilisateurs
    const selectedListEl = document.getElementsByClassName("number-selector-" + selectedOption.value)[0];
    // On met à jour le state nbUser
    setNbUser(selectedListEl.value)    
  };


  // Au changement dans la liste
  const handleClick = (e) => {
    e.preventDefault()
    const el = e.target
    // On change l'option sélectionné dans le select
    setSelectedOption({value: el.value, label: el.value + " " + t('pricing:users')}) 
    // On met à jour le state nbUser
    setNbUser(el.value)    
  }

  useEffect(() => {

  }, [nbUser])

  
  return (
    <>
      <div className="number-selector__container">
        <ul className="number-selector">
          {numbers.map((number, index) => 
            <li 
              value={number}
              key={index}
              onClick={handleClick}
              className={number === nbUser ? "number-selector-option number-selector-" + number + " selected " : "number-selector-option number-selector-" + number}>
              {number === 501 ?
              '+ 500'
              :
              number
              } {t('pricing:users')}
            </li>
          )}
        </ul>
      </div>

      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        className="nb-user-select"
      />

      <TarifTable nbUser={nbUser} />
    </>
  )
}

export default withTranslation()(NumberSelector)
