import React from 'react'
import images from '../../../ressources/images'
import './Footer.scss'
import { withTranslation } from 'react-i18next'

function Footer(props) {
  const {t} = props
  return (
    <footer>
      <div className="container">
        <div className="logo_container">
          <img src={images.logo} className="logo" alt=""/>
          <span>Point of control</span>
        </div>
        <ul className="navigation">
          <li><a href="#home">{t('common:home')}</a></li>
          <li><a href="#features">{t('common:features')}</a></li>
          <li><a href="#dashboard">{t('common:dashboard')}</a></li>
          <li><a href="#pricing">{t('common:pricing')}</a></li>
        </ul>
        <p className="copyright">Copyright 2020 By <a href="https://insitaction.com">Insitaction</a></p>
      </div>
    </footer>
  )
}

export default withTranslation()(Footer)
