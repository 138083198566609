import React from 'react'
import Fade from 'react-reveal/Fade'
import images from '../../../ressources/images'
import './Showcase.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { withTranslation } from 'react-i18next';

function Showcase(props) {
  const settings = {
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 5,      
    centerPadding: 0,
    speed: 500,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
          dots: true
        }
      }
    ]
  };
  
  const {t} = props
  
  return (
    <section id="showcase" className="showcase">
      <div className="container">
        <Fade bottom>
          <h2 className="title">{t('common:presentation')}</h2>
        </Fade>
        <div className="slider_container">
          <Slider {...settings}>
            <img src={images.showcase1} alt=""/>
            <img src={images.showcase2} alt=""/> 
            <img src={images.showcase3} alt=""/>
            <img src={images.showcase4} alt=""/>
            <img src={images.showcase5} alt=""/>
            <img src={images.showcase6} alt=""/>
            <img src={images.showcase7} alt=""/>
            <img src={images.showcase8} alt=""/>
          </Slider>
          <div className="smartphone">
            <img src={images.smartphone} alt=""/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(Showcase)
