import React from 'react';
import Fade from 'react-reveal/Fade'
import './Pricing.scss'
import NumberSelector from '../../../components/NumberSelector/NumberSelector';
import { withTranslation } from 'react-i18next';

function Pricing(props) {
  const {t} = props
    return (
      <section id="pricing" className="pricing">
        <div className="container">
          <Fade bottom>
            <h5 className="subtitle">{t('pricing:pricing')}</h5>
          </Fade>
          <Fade bottom>
            <NumberSelector/>
          </Fade>
        </div>
      </section>
    )
  }
  
  export default withTranslation()(Pricing)
  