import React from 'react'
import Fade from 'react-reveal/Fade'
import images from '../../../ressources/images'
import './Dashboard.scss'
import { withTranslation } from 'react-i18next'

function Dashboard(props) {
  const {t} = props
  return (
    <section id="dashboard" className="dashboard">
      <div className="text_container container">
        <div className="text">
          <Fade bottom>
            <h5 className="subtitle">{t('dashboard:subtitle')}</h5>
          </Fade>
          <Fade bottom>
            <h3 className="title">
            {t('dashboard:title')}
            </h3>
          </Fade>
          <Fade bottom>
            <p>
              {t('dashboard:text')}
            </p>
          </Fade>
        </div>
      </div>
      <div className="dashboard_preview">
        <img src={images.dashboard} alt=""/>
      </div>
    </section>
  )
}

export default withTranslation()(Dashboard)
