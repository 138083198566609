import './nav.scss'
import images from '../../ressources/images'
import React, { useState, useEffect, useRef } from 'react'
import { withTranslation } from 'react-i18next'
import LangSelector from '../LangSelector/LangSelector'

function Nav(props) {

  const [open, setOpen] = useState(false)
  const [scroll, setScroll] = useState(false)
  const nav = useRef()

  const openMenu = () => {
    setOpen(!open)
  }

  const scrollNav = () => {
    let navHeight = nav.current.offsetHeight
    
    window.addEventListener("scroll", () => {
       
      let scrollTop = window.pageYOffset
      if(scrollTop > navHeight) {
        setScroll(true)
      } else {
        setScroll(false)
      }
    })
  }

  useEffect(() => {
    scrollNav()
  }, [])

  const {t} = props
  return (
    <nav ref={nav} className={scroll ? "nav scroll" : "nav"}>
      <div className="nav_wrapper container">
        <a className="main_link" href="/">
          <img className="logo" src={images.logo} alt=""/>
          <span>Point of control</span>
        </a>
  
        <div className={open ? "header_container open" : "header_container"} onClick={openMenu}> 
            <span></span>
            <span></span>
            <span></span>
        </div>

        <div className={open ? "navbar open" : "navbar"}>
          <ul>
            <li><a href="#home">{t('common:home')}</a></li>
            <li><a href="#features">{t('common:features')}</a></li>
            <li><a href="#dashboard">{t('common:dashboard')}</a></li>
            <li><a href="#pricing">{t('common:pricing')}</a></li>
          </ul>
        </div>
        
        <LangSelector/>
      </div>
    </nav>
  )
}

export default withTranslation()(Nav)

